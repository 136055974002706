import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router/index'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASEURL
  // baseURL: 'http://192.168.1.110:8002'
  // timeout: 0
})
// service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// 请求拦截器
service.interceptors.request.use(config => {
  // console.log('请求拦截器', config)
  if (sessionStorage.getItem('token')) {
    const token = sessionStorage.getItem('token')
    config.headers.Authorization = token
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    // config.AccessControlAllowOrigin = '*'
  }
  return config
}, error => {
  console.log(error)
  return new Promise(resolve => {
    resolve(error)
  })
})

service.interceptors.response.use(function (response) {
  // 有data  有code
  // response.data.code += ''
  // console.log(response.data.code)
  const resCode = (response.data.code + '' !== 'undefined') ? (response.data.code + '') : ''
  // eslint-disable-next-line eqeqeq
  if (resCode && +response.data.code != 0) {
    // eslint-disable-next-line eqeqeq
    if (+response.data.code == -2) {
      Message({
        message: '登录过期，请重新登录',
        type: 'error'
      })
      sessionStorage.removeItem('token')

      router.replace('/login')
    } else {
      Message({
        message: response.data.msg,
        type: 'error'
      })
    }
  }
  // 对响应数据做点什么
  return response.data
}, function (error) {
  // 对响应错误做点什么
  console.log('报错了', error)
  Message({
    message: '对不起!服务器出现异常',
    type: 'error'
  })
})

export default service
