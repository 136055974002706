import Vue from 'vue'
import Vuex from 'vuex'
import { getUserInfoApi } from '../api/user'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: null
  },
  mutations: {
    setUserInfo (state, val) {
      state.userInfo = val
      sessionStorage.setItem('userInfo', JSON.stringify(val))
    }
  },
  actions: {
    // type 0 默认更新 1 强制接口更新
    async getUserInfo ({ commit }, type = 0) {
      // setTimeout(() => import)
      const user = JSON.parse(sessionStorage.getItem('userInfo'))
      if (user && !type) {
        commit('setUserInfo', user)
      } else {
        const res = await getUserInfoApi()
        console.log(res)
        if (res && res.code === 0) {
          commit('setUserInfo', res.data)
        }
      }
    }
  },
  modules: {
  }
})
