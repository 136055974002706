import request from './request'

// 基本信息
export function getUserInfoApi (data) {
  return request({
    url: '/client/user/userInfo',
    method: 'POST',
    data
  })
}

export function editAvatarApi (data) {
  return request({
    url: '/client/user/modifyHeadPortrait',
    method: 'POST',
    data
  })
}

export function editPhoneNumApi (data) {
  return request({
    url: '/client/user/modifyMobile',
    method: 'POST',
    data
  })
}

export function editPasswordApi (data) {
  return request({
    url: '/client/user/modifyPassword',
    method: 'POST',
    data
  })
}

// 实名认证
export function getAuthInfoApi (data) {
  return request({
    url: '/client/user/authInfo',
    method: 'POST',
    data
  })
}

export function authCompanyApi (data) {
  return request({
    url: '/client/user/authForCompany',
    method: 'POST',
    data
  })
}

export function authPersonalApi (data) {
  return request({
    url: '/client/user/authForPersonal',
    method: 'POST',
    data
  })
}

// 充值二维码
export function chargeTransactionsApi (data) {
  return request({
    url: '/client/order/chargeTransactions',
    method: 'POST',
    data
  })
}

// 额度预警
export function setAmountNotice (data) {
  return request({
    url: '/client/user/setAmountNotice',
    method: 'POST',
    data
  })
}
