import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    redirect: '/home',
    component: () => import(/* webpackChunkName: "login" */ '../views/Index.vue'),
    children: [
      {
        path: '/home',
        component: () => import('../views/home/Index.vue')
      },
      {
        path: '/accountCharge',
        component: () => import('../views/accountCharge/Index.vue')
      },
      {
        path: '/goods/apiGoods',
        component: () => import('../views/goods/apiGoods/Index.vue')
      },
      {
        path: '/goods/apiGoods/:id',
        name: 'GoodsInfo',
        component: () => import('../views/goods/apiGoods/GoodsInfo.vue')
      },
      {
        path: '/goods/myApi',
        component: () => import('../views/goods/myApi/Index.vue')
      },
      {
        path: '/goods/myApi/info',
        name: 'MyGoodsInfo',
        component: () => import('../views/goods/myApi/MyGoodsInfo.vue')
      },
      {
        path: '/cost/chargeList',
        component: () => import('../views/cost/chargeList/Index.vue')
      },
      {
        path: '/cost/spendList',
        component: () => import('../views/cost/spendList/Index.vue')
      },
      {
        path: '/cost/chargeMobileOrder',
        component: () => import('../views/cost/chargeMobileOrder/Index.vue')
      },
      {
        path: '/user/baseInfo',
        component: () => import('../views/user/baseInfo/Index.vue')
      },
      {
        path: '/user/auth',
        component: () => import('../views/user/auth/Index.vue')
      },
      {
        path: '/user/limitWarning',
        component: () => import('../views/user/limitWarning/Index.vue')
      },
      {
        path: '/user/apiDocking',
        component: () => import('../views/user/apiDocking/Index.vue')
      }

    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/Index.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

// 重写router的push方法 解决相同路由地址报错问题
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(error => error)
}

// 路由拦截
router.beforeEach((to, from, next) => {
  const login = sessionStorage.getItem('token')

  // 如果没有登录 并且跳转页面不是登录页 就跳转到登录页
  if (!login && to.path !== '/login') {
    next({
      path: '/login'
    })
  } else {
    // 如果已经登录 但是要跳转到登录页 则选择不跳转
    if (login && to.path === '/login') {
      next({
        path: from.path
      })
    } else {
      next()
    }
  }
})

export default router
