import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import './assets/iconfont/iconfont.css'

Vue.use(ElementUI)

Vue.config.productionTip = false

process.env.NODE_ENV !== 'development' && (window.console.log = () => { })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
